import { type VariantProps, cva } from "cva";
import { forwardRef } from "react";
import { cn } from "../../lib/cn";
import { Loading } from "../common/loading";

// Docs: https://cva.style/docs/getting-started/variants
const button = cva(
	"whitespace-nowrap rounded-xl relative hover:cursor-pointer disabled:cursor-not-allowed ease-in-out duration-200 flex flex-row items-center justify-center space-x-2 font-medium transition-all focus-visible:outline-none focus-visible:border-ring focus-visible:ring-[3px] focus-visible:ring-blue-700/40 shrink-0",
	{
		variants: {
			intent: {
				outline: "border bg-transparent disabled:opacity-50",
				solid: "",
			},
			color: { black: "" },
			size: { small: "text-sm px-3 h-[34px]", big: "text-base px-4 h-10" },
		},
		compoundVariants: [
			{
				intent: "outline",
				color: "black",
				class:
					"border-neutral-300 text-neutral-700 hover:border-neutral-400 hover:text-neutral-800",
			},
			{
				intent: "solid",
				color: "black",
				class:
					"bg-neutral-900 text-white hover:bg-black disabled:text-neutral-400",
			},
		],
		defaultVariants: {
			intent: "outline",
			color: "black",
			size: "small",
		},
	},
);

export type ButtonProps = {
	icon?: React.ReactNode;
	iconPosition?: "left" | "right";
	isLoading?: boolean;
	innerClassName?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
	VariantProps<typeof button>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			intent,
			color,
			size,
			icon,
			iconPosition = "right",
			isLoading,
			className,
			innerClassName,
			children,
			...buttonProps
		}: ButtonProps,
		ref,
	) => {
		return (
			<button
				type="button"
				{...buttonProps}
				ref={ref}
				className={button({ intent, color, size, className })}
			>
				{iconPosition === "left" && !isLoading && icon}
				{!!children && (
					<div
						className={cn(
							"flex flex-row items-center space-x-2",
							innerClassName,
						)}
					>
						{isLoading ? (
							<div className="relative h-5 w-5">
								<Loading className="w-min h-min absolute top-0 bottom-0 left-0 right-0" />
							</div>
						) : (
							<>{children}</>
						)}
					</div>
				)}
				{iconPosition === "right" && !isLoading && icon}
			</button>
		);
	},
);

Button.displayName = "Button";
