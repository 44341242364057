import { useQueryState } from "nuqs";
import { useFormContext } from "react-hook-form";
import type { FormValues } from "./payment-wrapper";

type Props = {
	email: string | null;
};

export function CustomerInformation({ email }: Props) {
	const [_, setEmail] = useQueryState("email");
	const {
		register,
		formState: { errors },
	} = useFormContext<FormValues>();

	return (
		<fieldset className="flex flex-col space-y-1">
			<div className="flex items-center justify-between">
				<label htmlFor="email" className="text-sm text-neutral-500 font-medium">
					Email
				</label>
				{errors.email && (
					<span className="text-sm text-red-500">{errors.email.message}</span>
				)}
			</div>
			<input
				id="email"
				type="text"
				disabled={!!email}
				placeholder="Billing email address"
				className="w-full px-3 py-1.5 text-sm border border-neutral-300 hover:border-neutral-400 rounded-xl duration-200 ease-in-out transition-all focus-visible:outline-none focus-visible:border-ring focus-visible:ring-[3px] focus-visible:ring-blue-700/40"
				{...register("email")}
				onChange={(e) => {
					setEmail(e.target.value);
					register("email").onChange(e);
				}}
			/>
		</fieldset>
	);
}
