import { useSearch } from "@tanstack/react-router";
import { PayWithBank } from "../payment-methods/bank";

export function ManagePaymentMethods() {
	const { method } = useSearch({
		from: "/pay/$paymentId",
	});

	if (method === "bank") {
		return <PayWithBank />;
	}

	return null;
}
