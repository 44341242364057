import { createRoute, useParams } from "@tanstack/react-router";
import { z } from "zod";
import { sessionOptions, useSuspenseSession } from "../api/use-session";
import { Loading } from "../components/common/loading";
import { Message } from "../components/common/message";
import { queryClient, rootRoute } from "./__root";

export const linkSchema = z.object({
	checkoutSessionUrl: z.string(),
});

export const linkRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: "/link/$linkId",
	loader: async ({ params }) => {
		await queryClient.ensureQueryData(sessionOptions(params.linkId));
	},
	component: LinkPage,
	errorComponent: () => (
		<Message
			title="Something went wrong"
			content="The page you were looking for could not be found. Please check the URL or contact the merchant."
		/>
	),
});

function LinkPage() {
	const { linkId } = useParams({ from: "/link/$linkId" });
	const { session } = useSuspenseSession(linkId);

	if (session.checkoutSessionUrl) {
		window.location.replace(session.checkoutSessionUrl);
	}

	return <Loading className="w-screen h-screen" />;
}
