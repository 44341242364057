import { useCopyToClipboard } from "@uidotdev/usehooks";
import { useEffect, useRef, useState } from "react";
import { CheckIcon } from "../../assets/check";
import { CopyIcon } from "../../assets/copy";

type Props = {
	data: string;
};

export function CopyableButton({ data }: Props) {
	const [isCopied, setIsCopied] = useState(false);
	const [_, copyToClipboard] = useCopyToClipboard();
	const timeout = useRef<NodeJS.Timeout | null>(null);

	function copy(data: string) {
		if (timeout.current) {
			return;
		}

		setIsCopied(true);
		copyToClipboard(data);

		timeout.current = setTimeout(() => {
			setIsCopied(false);

			timeout.current = null;
		}, 1500);
	}

	useEffect(() => {
		return () => clearTimeout(timeout.current as NodeJS.Timeout);
	}, []);

	return (
		<button
			type="button"
			onClick={() => copy(data)}
			className="px-3 py-1.5 w-full flex items-center justify-between space-x-2 border border-neutral-200 bg-neutral-50 rounded-xl hover:cursor-pointer disabled:cursor-not-allowed ease-in-out duration-200 transition-all focus-visible:outline-none focus-visible:border-ring focus-visible:ring-[3px] focus-visible:ring-blue-700/40"
		>
			<code className="text-left text-sm text-neutral-700">{data}</code>
			{isCopied ? (
				<CheckIcon className="w-4 h-4 text-neutral-700 flex-shrink-0" />
			) : (
				<CopyIcon className="w-4 h-4 text-neutral-700 flex-shrink-0" />
			)}
		</button>
	);
}
