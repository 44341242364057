import { useQuery } from "@tanstack/react-query";
import { useParams, useSearch } from "@tanstack/react-router";
import { useContext, useEffect, useState } from "react";
import { isDesktop } from "react-device-detect";
import QRCode from "react-qr-code";
import { z } from "zod";
import { useBank } from "../../api/use-bank";
import { useSuspensePayment } from "../../api/use-payment";
import { BankIcon } from "../../assets/bank";
import { LinkIcon } from "../../assets/link";
import { LockIcon } from "../../assets/lock";
import { ShieldIcon } from "../../assets/shield";
import { XIcon } from "../../assets/x";
import { fetcher } from "../../lib/fetcher";
import { ModalContext } from "../../providers/modal";
import { Loading } from "../common/loading";
import { Button } from "../ui/button";

type Props = {
	bankId: string;
};

export function ConfirmBankConnection({ bankId }: Props) {
	const ctx = useContext(ModalContext);
	const [hasContinued, setHasContinued] = useState(false);
	const { paymentId } = useParams({ from: "/pay/$paymentId" });
	const { payment } = useSuspensePayment(paymentId);
	const { locale, email } = useSearch({ from: "/pay/$paymentId" });
	const { bank } = useBank({
		paymentId,
		bankId: bankId || "",
		locale,
	});
	const { data, isLoading } = useQuery<{
		linkUrl: string;
	}>({
		queryKey: ["session", email, bankId, paymentId],
		queryFn: async () =>
			fetcher(
				`${import.meta.env.VITE_API_URL}/checkout/submit/${paymentId}`,
				z.object({
					linkUrl: z.string(),
				}),
				{
					method: "POST",
					body: JSON.stringify({
						email: payment.customerEmail ?? email,
						bankId: bankId,
					}),
				},
			),
	});

	useEffect(() => {
		if (hasContinued && !isDesktop && data?.linkUrl) {
			window.location.replace(data?.linkUrl);
		}
	}, [data?.linkUrl, hasContinued]);

	if (!bank) {
		return (
			<div className="h-screen w-screen md:h-2/3 md:min-h-[32rem] md:max-h-[60rem] md:w-full md:max-w-sm flex flex-col items-center justify-between rounded-3xl bg-white">
				<Loading />
			</div>
		);
	}

	return (
		<div className="h-screen w-screen md:h-2/3 md:min-h-[32rem] md:max-h-[60rem] md:w-full md:max-w-sm flex flex-col items-center justify-between space-y-10 p-3 md:rounded-3xl bg-white">
			<div className="h-full w-full flex flex-col items-center space-y-2">
				<div className="w-full flex justify-end">
					<button
						type="button"
						onClick={() => ctx?.close()}
						className="mt-0.5 h-6 w-6 -left-10 mr-2 flex justify-center items-center rounded-full cursor-pointer duration-200 ease-in-out transition-all focus-visible:outline-none focus-visible:border-ring focus-visible:ring-[3px] focus-visible:ring-blue-700/40"
					>
						<XIcon className="w-5 h-5 text-neutral-700" />
					</button>
				</div>
				{bank.logo ? (
					<img
						src={bank?.logo}
						alt={`${bank?.name} logo`}
						className="shrink-0 h-20 w-20"
					/>
				) : (
					<div className="shrink-0 w-20 h-20 flex justify-center items-center bg-neutral-100 rounded-full">
						<BankIcon className="w-8 h-8 text-neutral-600" />
					</div>
				)}
				<span className="text-2xl font-semibold text-neutral-700 text-center">
					Log in with {bank?.name}
				</span>
				<div className="h-10" />
				{hasContinued && data?.linkUrl && isDesktop ? (
					<div className="h-full flex flex-col items-center justify-between">
						<div className="flex flex-col items-center space-y-8">
							<span className="text-base text-center font-medium text-neutral-600">
								Scan this QR code with your phone camera to complete the bank
								login in the app
							</span>
							<QRCode value={data.linkUrl} className="w-40 h-40" />
						</div>
						<span className="text-sm text-neutral-500">
							Want to continue on your computer?{" "}
							<a
								href={data.linkUrl}
								target="_blank"
								rel="noreferrer"
								className="underline"
							>
								Click here
							</a>
						</span>
					</div>
				) : (
					<div className="flex flex-col space-y-2">
						<div className="flex items-start space-x-3">
							<LinkIcon className="shrink-0 mt-0.5 w-5 h-5 text-neutral-600" />
							<span className="text-base font-medium text-neutral-600">
								{payment.merchant} uses Inflow to connect to your acounts.
							</span>
						</div>
						<div className="flex items-start space-x-3">
							<ShieldIcon className="shrink-0 mt-0.5 w-5 h-5 text-neutral-600" />
							<span className="text-base font-medium text-neutral-600">
								Your data is 100% encrypted
							</span>
						</div>
					</div>
				)}
			</div>
			{isLoading || !hasContinued ? (
				<div className="w-full flex flex-col space-y-2 items-center">
					<span className="text-xs text-neutral-400 font-medium flex space-x-2 items-center">
						<LockIcon className="w-3 h-3 fill-green-400" />
						<span>Secured by Inflow</span>
					</span>
					<Button
						intent="solid"
						size="big"
						type="button"
						onClick={() => setHasContinued(true)}
						className="w-full"
					>
						{!hasContinued ? "Continue" : "Connecting to bank..."}
					</Button>
				</div>
			) : null}
		</div>
	);
}
