import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams, useSearch } from "@tanstack/react-router";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useSuspensePayment } from "../../api/use-payment";
import { AlertIcon } from "../../assets/alert";
import { BankIcon } from "../../assets/bank";
import { ChevronIcon } from "../../assets/chevron";
import { HistoryIcon } from "../../assets/history";
import { LockIcon } from "../../assets/lock";
import { ShieldIcon } from "../../assets/shield";
import { XIcon } from "../../assets/x";
import { ZapIcon } from "../../assets/zap";
import { fetcher } from "../../lib/fetcher";
import { numberFormatter } from "../../lib/number";
import { ModalContext } from "../../providers/modal";
import { Loading } from "../common/loading";
import { Button } from "../ui/button";
import { CopyableButton } from "../ui/copyable-button";

export function ManualBankTransfer() {
	const ctx = useContext(ModalContext);
	const [hasContinued, setHasContinued] = useState(false);
	const { paymentId } = useParams({ from: "/pay/$paymentId" });
	const { payment } = useSuspensePayment(paymentId);
	const { email } = useSearch({ from: "/pay/$paymentId" });
	const { data, isLoading } = useQuery({
		queryKey: ["gen-iban", email, paymentId],
		queryFn: async () =>
			fetcher(
				`${import.meta.env.VITE_API_URL}/checkout/generateIban/${paymentId}`,
				z.object({
					payment_rail: z.enum(["sepa"]),
					currency: z.string(),
					amount: z.coerce.number(),
					deposit_message: z.string(),
					iban: z.string(),
					bic: z.string(),
					account_holder_name: z.string(),
					bank_name: z.string(),
					bank_address: z.string(),
				}),
				{
					method: "POST",
					body: JSON.stringify({
						email: payment.customerEmail ?? email,
					}),
				},
			),
	});

	useEffect(() => {
		if (hasContinued && !isLoading && !!data) {
			ctx?.open(<DepositInstructions />);
		}
	}, [hasContinued, isLoading, data, ctx]);

	return (
		<div className="h-screen w-screen md:h-2/3 md:min-h-[32rem] md:max-h-[60rem] md:w-full md:max-w-sm flex flex-col items-center justify-between space-y-10 p-3 md:rounded-3xl bg-white">
			<div className="h-full w-full flex flex-col items-center space-y-2">
				<div className="w-full flex justify-end p-2">
					<button
						type="button"
						onClick={() => ctx?.close()}
						className="h-6 w-6 flex justify-center items-center rounded-full cursor-pointer duration-200 ease-in-out transition-all focus-visible:outline-none focus-visible:border-ring focus-visible:ring-[3px] focus-visible:ring-blue-700/40"
					>
						<XIcon className="w-5 h-5 text-neutral-700" />
					</button>
				</div>
				<div className="shrink-0 w-20 h-20 flex justify-center items-center bg-neutral-100 rounded-full">
					<BankIcon className="w-8 h-8 text-neutral-600" />
				</div>
				<span className="text-2xl font-semibold text-neutral-700 text-center">
					Pay with bank transfer
				</span>
				<div className="h-6" />
				<div className="flex flex-col space-y-2">
					<div className="flex items-start space-x-3">
						<ZapIcon className="shrink-0 mt-0.5 w-5 h-5 text-neutral-600" />
						<span className="text-base font-medium text-neutral-600">
							SEPA instant transfer may take up to a minute to process
						</span>
					</div>
					<div className="flex items-start space-x-3">
						<HistoryIcon className="shrink-0 mt-0.5 w-5 h-5 text-neutral-600" />
						<span className="text-base font-medium text-neutral-600">
							Normal SEPA transfers usually take 1-3 business days
						</span>
					</div>
					<div className="flex items-start space-x-3">
						<ShieldIcon className="shrink-0 mt-0.5 w-5 h-5 text-neutral-600" />
						<span className="text-base font-medium text-neutral-600">
							Your data is 100% encrypted
						</span>
					</div>
				</div>
			</div>
			<div className="w-full flex flex-col space-y-2 items-center">
				<span className="text-xs text-neutral-400 font-medium flex space-x-2 items-center">
					<LockIcon className="w-3 h-3 fill-green-400" />
					<span>Secured by Inflow</span>
				</span>
				<Button
					intent="solid"
					size="big"
					type="button"
					onClick={() => setHasContinued(true)}
					className="w-full"
				>
					{!hasContinued ? "Continue" : "Connecting to bank..."}
				</Button>
			</div>
		</div>
	);
}

const formSchema = z.object({
	bankName: z.string(),
});

export type FormValues = z.infer<typeof formSchema>;

function DepositInstructions() {
	const ctx = useContext(ModalContext);
	const { paymentId } = useParams({ from: "/pay/$paymentId" });
	const { payment } = useSuspensePayment(paymentId);
	const { email } = useSearch({ from: "/pay/$paymentId" });
	const { data } = useQuery({
		queryKey: ["gen-iban", email, paymentId],
		queryFn: async () =>
			fetcher(
				`${import.meta.env.VITE_API_URL}/checkout/generateIban/${paymentId}`,
				z.object({
					payment_rail: z.enum(["sepa"]),
					currency: z.string(),
					amount: z.coerce.number(),
					deposit_message: z.string(),
					iban: z.string(),
					bic: z.string(),
					account_holder_name: z.string(),
					bank_name: z.string(),
					bank_address: z.string(),
				}),
				{
					method: "POST",
					body: JSON.stringify({
						email: payment.customerEmail ?? email,
					}),
				},
			),
	});

	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm<FormValues>({
		resolver: zodResolver(formSchema),
		mode: "onSubmit",
		reValidateMode: "onChange",
		defaultValues: {
			bankName: "",
		},
	});

	const { mutate, isPending } = useMutation({
		mutationKey: ["confirm-deposit", email, paymentId],
		mutationFn: async (bankName: string) =>
			fetch(
				`${
					import.meta.env.VITE_API_URL
				}/checkout/confirmBankTransfer/${paymentId}`,
				{
					method: "POST",
					body: JSON.stringify({
						customerBankName: bankName,
					}),
				},
			),
		onSuccess: () => {
			ctx?.open(<ConfirmDeposit paymentId={paymentId} />);
		},
	});

	function onSubmit(data: FormValues) {
		mutate(data.bankName);
	}

	if (!data) {
		return (
			<div className="h-screen w-screen md:h-2/3 md:min-h-[32rem] md:max-h-[60rem] md:w-full md:max-w-sm flex flex-col items-center justify-between rounded-3xl bg-white">
				<Loading />
			</div>
		);
	}

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className="h-screen w-screen md:h-2/3 md:min-h-[32rem] md:max-h-[60rem] md:w-full md:max-w-sm flex flex-col items-center justify-between space-y-10 p-3 md:rounded-3xl bg-white"
		>
			<div className="h-full w-full flex flex-col items-center space-y-2">
				<div className="w-full flex justify-end p-2">
					<button
						type="button"
						onClick={() => ctx?.close()}
						className="h-6 w-6 flex justify-center items-center rounded-full cursor-pointer duration-200 ease-in-out transition-all focus-visible:outline-none focus-visible:border-ring focus-visible:ring-[3px] focus-visible:ring-blue-700/40"
					>
						<XIcon className="w-5 h-5 text-neutral-700" />
					</button>
				</div>
				<div className="shrink-0 w-20 h-20 flex justify-center items-center bg-neutral-100 rounded-full">
					<BankIcon className="w-8 h-8 text-neutral-600" />
				</div>
				<span className="text-2xl font-semibold text-neutral-700 text-center">
					Pay with bank transfer
				</span>
				<div className="h-6" />
				<div className="flex flex-col space-y-4 overflow-y-auto px-1 pb-1">
					<div className="w-full flex items-start space-x-2">
						<div className="h-full flex flex-col items-center shrink-0">
							<div className="bg-neutral-100 w-12 h-12 flex justify-center items-center rounded-full shrink-0">
								<span className="text-neutral-600 font-semibold text-lg">
									1
								</span>
							</div>
							<div className="w-[2px] bg-neutral-100 h-full" />
						</div>
						<div className="w-full flex flex-col space-y-4">
							<div className="flex items-start px-3 py-2 space-x-3 bg-amber-100 rounded-xl">
								{/* <ZapIcon className="shrink-0 mt-0.5 w-5 h-5 text-neutral-600" /> */}
								<span className="text-xs font-medium text-amber-600">
									Please only send{" "}
									{numberFormatter(payment.amount / 100, {
										currency: payment.currency,
										style: "currency",
									})}{" "}
									to the account below. Sending more than{" "}
									{numberFormatter(payment.amount / 100, {
										currency: payment.currency,
										style: "currency",
									})}{" "}
									will result in your payment being reverted.
								</span>
							</div>
							<div className="flex flex-col space-y-1">
								<span className="text-xs font-medium text-neutral-500">
									Bank Country
								</span>
								<CopyableButton data="Ireland" />
							</div>
							<div className="flex flex-col space-y-1">
								<span className="text-xs font-medium text-neutral-500">
									IBAN
								</span>
								<CopyableButton data={data.iban} />
							</div>
							<div className="flex flex-col space-y-1">
								<span className="text-xs font-medium text-neutral-500">
									BIC/SWIFT
								</span>
								<CopyableButton data={data.bic} />
							</div>
							<div className="flex flex-col space-y-1">
								<div className="flex justify-between items-center">
									<span className="text-xs font-medium text-neutral-500">
										Reference Number
									</span>
									<div className="flex items-center space-x-1">
										<AlertIcon className="w-3.5 h-3.5 text-red-600" />
										<span className="text-xs font-medium text-red-600">
											Required
										</span>
									</div>
								</div>
								<CopyableButton data={data.deposit_message} />
							</div>
						</div>
					</div>
					<div className="w-full flex items-start space-x-2">
						<div className="h-full flex flex-col items-center shrink-0">
							<div className="bg-neutral-100 w-12 h-12 flex justify-center items-center rounded-full shrink-0">
								<span className="text-neutral-600 font-semibold text-lg">
									2
								</span>
							</div>
							<div className="w-[2px] bg-neutral-100 h-full" />
						</div>
						<div className="w-full flex flex-col space-y-4">
							<div className="w-full flex flex-col space-y-4 items-center">
								<fieldset className="w-full flex flex-col space-y-1">
									<div className="flex items-center justify-between">
										<label
											htmlFor="bankName"
											className="text-xs text-neutral-500 font-medium"
										>
											Bank used
										</label>
										{errors.bankName && (
											<span className="text-sm text-red-500">
												{errors.bankName.message}
											</span>
										)}
									</div>
									<input
										id="bankName"
										type="text"
										placeholder="e.g. Wise"
										className="w-full px-3 py-1.5 text-sm border border-neutral-300 hover:border-neutral-400 rounded-xl duration-200 ease-in-out transition-all focus-visible:outline-none focus-visible:border-ring focus-visible:ring-[3px] focus-visible:ring-blue-700/40"
										{...register("bankName")}
									/>
								</fieldset>
							</div>
						</div>
					</div>
					<Button
						intent="solid"
						size="big"
						type="submit"
						className="w-full"
						isLoading={isPending}
					>
						Confirm payment
					</Button>
				</div>
			</div>
		</form>
	);
}

type Props = {
	paymentId: string;
};

function ConfirmDeposit({ paymentId }: Props) {
	const ctx = useContext(ModalContext);
	const navigate = useNavigate();

	return (
		<div className="h-screen w-screen md:h-2/3 md:min-h-[32rem] md:max-h-[60rem] md:w-full md:max-w-sm flex flex-col items-center justify-between space-y-10 p-3 md:rounded-3xl bg-white">
			<div className="h-full w-full flex flex-col items-center space-y-2">
				<div className="w-full flex justify-between p-2">
					<button
						type="button"
						onClick={() => ctx?.open(<DepositInstructions />)}
						className="h-6 w-6 flex justify-center items-center rounded-full cursor-pointer duration-200 ease-in-out transition-all focus-visible:outline-none focus-visible:border-ring focus-visible:ring-[3px] focus-visible:ring-blue-700/40"
					>
						<ChevronIcon className="w-5 h-5 text-neutral-700 rotate-90" />
					</button>
					<button
						type="button"
						onClick={() => ctx?.close()}
						className="h-6 w-6 flex justify-center items-center rounded-full cursor-pointer duration-200 ease-in-out transition-all focus-visible:outline-none focus-visible:border-ring focus-visible:ring-[3px] focus-visible:ring-blue-700/40"
					>
						<XIcon className="w-5 h-5 text-neutral-700" />
					</button>
				</div>
				<div className="shrink-0 w-20 h-20 flex justify-center items-center bg-neutral-100 rounded-full">
					<BankIcon className="w-8 h-8 text-neutral-600" />
				</div>
				<span className="text-2xl font-semibold text-neutral-700 text-center">
					Payment check
				</span>
				<div className="h-6" />
			</div>
			<div className="w-full flex flex-col space-y-2 items-center">
				<span className="text-sm font-medium text-neutral-500 text-center">
					By confirming, you attest that the payment was done
				</span>
				<Button
					intent="solid"
					size="big"
					type="button"
					onClick={() => {
						ctx?.close();

						navigate({
							to: "/success",
							search: {
								inflow_payment_id: paymentId,
							},
						});
					}}
					className="w-full"
				>
					I confirm
				</Button>
			</div>
		</div>
	);
}
