import {
	Link,
	useNavigate,
	useParams,
	useSearch,
} from "@tanstack/react-router";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { z } from "zod";
import { useBanks } from "../../api/use-banks";
import { useSuspensePayment } from "../../api/use-payment";
import { BankIcon } from "../../assets/bank";
import { CloseIcon } from "../../assets/close";
import { LockIcon } from "../../assets/lock";
import { bankLocales } from "../../data/supported-locales";
import { cn } from "../../lib/cn";
import { isoToFlag } from "../../lib/iso-to-flag";
import { ModalContext } from "../../providers/modal";
import { ManualBankTransfer } from "../modals/manual-bank-transfer";
import type { FormValues } from "../payments/payment-wrapper";
import { Dropdown } from "../ui/dropdown";

export function PayWithBank() {
	const ref = useRef<HTMLInputElement>(null);
	const ctx = useContext(ModalContext);
	const navigate = useNavigate({ from: "/pay/$paymentId" });
	const { paymentId } = useParams({ from: "/pay/$paymentId" });
	const { payment } = useSuspensePayment(paymentId);
	const { locale, bankId } = useSearch({ from: "/pay/$paymentId" });
	const [search, setSearch] = useState("");
	const { banks, isLoading } = useBanks({
		paymentId,
		locale,
	});

	const {
		getValues,
		setError,
		formState: { errors },
	} = useFormContext<FormValues>();

	const filteredBanks = useMemo(() => {
		if (!banks) return [];

		return banks.banks
			.filter((bank) => bank.name.toLowerCase().includes(search.toLowerCase()))
			.slice(0, 6);
	}, [banks, search]);

	useEffect(() => {
		if (!banks) {
			return;
		}

		if (banks.locale !== locale) {
			navigate({
				to: "/pay/$paymentId",
				params: { paymentId },
				search: (curr) => ({
					...curr,
					locale: banks.locale,
				}),
			});
		}
	}, [banks, navigate, locale, paymentId]);

	return (
		<div className="w-full flex flex-col space-y-4">
			{!locale && isLoading ? (
				<div className="h-[34px] w-full rounded-xl bg-neutral-100 animate-pulse" />
			) : (
				<Dropdown
					options={bankLocales}
					defaultSelected={locale || banks?.locale}
					onChange={({ value }) => {
						navigate({
							to: "/pay/$paymentId",
							params: { paymentId },
							search: (curr) => ({
								...curr,
								locale: value,
								bankId: undefined,
							}),
						});
					}}
					render={({ label, value }) => (
						<div className="px-3 py-1 text-left text-sm text-neutral-800 flex space-x-2 items-center">
							<span className="leading-3 mt-1">{isoToFlag(value)}</span>
							<span>{label}</span>
						</div>
					)}
				/>
			)}
			<fieldset className="flex flex-col space-y-1">
				<div className="flex items-center justify-between">
					<label
						htmlFor="bankSearch"
						className="text-sm text-neutral-500 font-medium"
					>
						Search for your bank
					</label>
					<span className="text-xs text-neutral-400 font-medium flex space-x-2 items-center">
						<LockIcon className="w-3 h-3 fill-green-400" />
						<span>Secured by Inflow</span>
					</span>
				</div>
				<div className="w-full flex items-center border border-neutral-300 hover:border-neutral-400 rounded-xl has-[:focus-visible]:border-neutral-400 duration-200 ease-in-out transition-all has-[:focus-visible]:outline-none has-[:focus-visible]:border-ring has-[:focus-visible]:ring-[3px] has-[:focus-visible]:ring-blue-700/40 overflow-hidden">
					<input
						ref={ref}
						id="bankSearch"
						type="text"
						placeholder="Search for your bank"
						className="w-full px-3 py-1.5 text-sm outline-none"
						value={search}
						onChange={(e) => setSearch(e.target.value)}
					/>
					<div
						className={cn(
							"px-2 py-1.5",
							search.length === 0 ? "hidden" : "flex",
						)}
					>
						<button
							type="button"
							className="rounded-full duration-200 ease-in-out transition-all focus-visible:outline-none focus-visible:border-ring focus-visible:ring-[3px] focus-visible:ring-blue-700/40"
							onClick={() => {
								setSearch("");
								ref.current?.focus();
							}}
						>
							<CloseIcon className="w-4 h-4 stroke-neutral-500" />
						</button>
					</div>
				</div>
				{errors.bankId && (
					<span className="text-sm text-red-500">You must select a bank</span>
				)}
			</fieldset>
			<div className="grid grid-cols-2 xxs:grid-cols-3 gap-4">
				{filteredBanks.map((bank, i) => (
					<Link
						key={`${bank.id}-${i}`}
						to="/pay/$paymentId"
						from="/pay/$paymentId"
						params={{ paymentId }}
						search={(c) => ({
							...c,
							bankId: bank.id,
						})}
						className={cn(
							"w-full h-full p-4 flex flex-col items-center space-y-2 border rounded-xl hover:border-neutral-400 duration-200 ease-in-out transition-all focus-visible:outline-none focus-visible:border-ring focus-visible:ring-[3px] focus-visible:ring-blue-700/40 shadow-sm",
							bankId === bank.id
								? "border-neutral-500 bg-neutral-100 font-semibold"
								: "border-neutral-300",
						)}
					>
						{bank.logo ? (
							<img
								src={bank.logo}
								alt={`${bank.name} logo`}
								className="max-w-full h-14 aspect-auto"
							/>
						) : (
							<div className="w-14 h-14 flex justify-center items-center bg-neutral-100 rounded-full">
								<BankIcon className="w-5 h-5 text-neutral-600" />
							</div>
						)}
					</Link>
				))}
				{filteredBanks.length === 0 && !isLoading && (
					<div className="col-span-2 xxs:col-span-3 flex justify-center items-center p-4 border border-dashed border-neutral-300 rounded-xl">
						<span className="text-neutral-500 text-sm font-semibold">
							No banks found
						</span>
					</div>
				)}
				{isLoading &&
					[...Array(6)].fill(null).map((_, i) => (
						<div
							// biome-ignore lint/suspicious/noArrayIndexKey: what else
							key={i}
							className="flex flex-col items-center space-y-2 p-4 border border-neutral-300 rounded-xl animate-pulse"
						>
							<div className="w-14 h-14 bg-neutral-100 rounded-full" />
						</div>
					))}
			</div>
			{payment.isLink ? (
				<button
					type="button"
					onClick={() => {
						if (
							payment.isEmailRequired &&
							!z.string().email().safeParse(getValues("email")).success
						) {
							setError("email", {
								message: "Email required",
							});

							return;
						}

						ctx?.open(<ManualBankTransfer />);
					}}
					className="text-left text-sm font-semibold text-neutral-500"
				>
					<span className="underline decoration-dotted">
						Pay manually via bank transfer
					</span>{" "}
					(usually takes 1-3 days)
				</button>
			) : null}
		</div>
	);
}
