export const bankLocales = [
	{
		value: "AT",
		label: "Austria",
		alias: ["Österreich", "Österrike", "Oostenrijk"],
	},
	{
		value: "BE",
		label: "Belgium",
		alias: ["België", "Belgie", "Belgique"],
	},
	{
		value: "BG",
		label: "Bulgaria",
		alias: ["България", "Bulgarien", "Bulgarie"],
	},
	{
		value: "DE",
		label: "Germany",
		alias: ["Deutschland", "Alemania", "Allemagne"],
	},
	{
		value: "DK",
		label: "Denmark",
		alias: ["Danmark", "Dänemark"],
	},
	{
		value: "EE",
		label: "Estonia",
		alias: ["Eesti", "Estland"],
	},
	{
		value: "ES",
		label: "Spain",
		alias: ["España", "Spanien", "Espagne"],
	},
	{
		value: "FI",
		label: "Finland",
		alias: ["Suomi", "Finlandia", "Finnland"],
	},
	{
		value: "FR",
		label: "France",
		alias: ["France", "Frankreich", "Francia"],
	},
	{
		value: "HU",
		label: "Hungary",
		alias: ["Magyarország", "Ungarn", "Hongrie"],
	},
	{
		value: "IE",
		label: "Ireland",
		alias: ["Ireland", "Irland", "Irlanda"],
	},
	{
		value: "IT",
		label: "Italy",
		alias: ["Italia", "Italien", "Italia"],
	},
	{
		value: "LV",
		label: "Latvia",
		alias: ["Latvija", "Lettland"],
	},
	{
		value: "LT",
		label: "Lithuania",
		alias: ["Lietuva", "Litauen"],
	},
	{
		value: "LU",
		label: "Luxembourg",
		alias: ["Lëtzebuerg", "Luxemburg", "Luxembourg"],
	},
	{
		value: "NL",
		label: "Netherlands",
		alias: ["Nederland", "Holland", "Pays-Bas"],
	},
	{
		value: "NO",
		label: "Norway",
		alias: ["Norge", "Norwegen", "Norvège"],
	},
	{
		value: "PL",
		label: "Poland",
		alias: ["Polska", "Polen", "Pologne"],
	},
	{
		value: "PT",
		label: "Portugal",
		alias: ["Portugal", "Portogallo"],
	},
	{
		value: "RO",
		label: "Romania",
		alias: ["România", "Rumänien", "Roumanie"],
	},
	{
		value: "SK",
		label: "Slovakia",
		alias: ["Slovensko", "Slowakei", "Slovaquie"],
	},
	{
		value: "SE",
		label: "Sweden",
		alias: ["Sverige", "Schweden", "Suède"],
	},
];
