import { createRouter } from "@tanstack/react-router";
import { rootRoute } from "./pages/__root";
import { paymentLinkRoute } from "./pages/l";
import { linkRoute } from "./pages/link";
import { loadingRoute } from "./pages/loading";
import { paymentRoute } from "./pages/pay";
import { successRoute } from "./pages/success";

const routeTree = rootRoute.addChildren([
	paymentRoute,
	linkRoute,
	paymentLinkRoute,
	loadingRoute,
	successRoute,
]);

export const router = createRouter({ routeTree, defaultPreload: "intent" });

declare module "@tanstack/react-router" {
	interface Register {
		router: typeof router;
	}
}
