import type { SVGProps } from "react";

export function CheckIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			strokeWidth="2.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			aria-label="Check icon"
			{...props}
		>
			<title>Check icon</title>
			<path d="M20 6 9 17l-5-5" />
		</svg>
	);
}
