import {
	queryOptions,
	useQuery,
	useSuspenseQuery,
} from "@tanstack/react-query";
import { fetcher } from "../lib/fetcher";
import { paymentSchema } from "../schemas/payment";
import type { QueryOptions } from "./types";

type Options = QueryOptions<Awaited<ReturnType<typeof getPayment>>>;

async function getPayment(id: string) {
	const data = await fetcher(
		`${import.meta.env.VITE_API_URL}/checkout/payment/${id}`,
		paymentSchema,
	);

	return {
		...data,
		isLink: new URL(data.successUrl).host.endsWith("inflowpay.xyz"),
	};
}

export function paymentOptions(id: string, options: Options = {}) {
	return queryOptions({
		queryKey: ["payment", id],
		queryFn: () => getPayment(id),
		staleTime: 10 * 1000,
		...options,
	});
}

export function usePayment(id: string, options: Options = {}) {
	const { data, ...query } = useQuery(paymentOptions(id, options));

	return {
		payment: data,
		...query,
	};
}

export function useSuspensePayment(id: string, options: Options = {}) {
	const { data, ...query } = useSuspenseQuery(paymentOptions(id, options));

	return {
		payment: data,
		...query,
	};
}
