import {
	Menu,
	MenuButton,
	MenuItem,
	MenuItems,
	Transition,
} from "@headlessui/react";
import { Fragment, useState } from "react";
import { ChevronIcon } from "../../assets/chevron";
import { cn } from "../../lib/cn";
import { norm } from "../../lib/norm";
import { Button } from "./button";

type Option = {
	value: string;
	label: string;
	alias?: string[];
};

type Dropdown<T extends Option> = {
	options: T[];
	defaultSelected?: T["value"];
	onChange?: (value: T) => void;
	buttonProps?: React.ComponentProps<typeof Button>;
	render?: (option: T) => React.ReactNode;
};

export function Dropdown<T extends Option>({
	options,
	defaultSelected,
	onChange,
	buttonProps,
	render,
}: Dropdown<T>) {
	const [query, setQuery] = useState("");
	const [selected, setSelected] = useState<T>(
		options.find((v) => v.value === defaultSelected) || options[0],
	);

	return (
		<Menu as="div" className="relative">
			{({ open }) => (
				<>
					<MenuButton
						as="div"
						className="flex w-full cursor-pointer items-center space-x-2"
					>
						<Button
							className="w-full !justify-start"
							innerClassName="w-min"
							icon={
								<ChevronIcon
									className={cn(
										"w-4 h-4 transition-all",
										open ? "rotate-180" : "rotate-0",
									)}
								/>
							}
							iconPosition="right"
							{...buttonProps}
						>
							{selected.label}
						</Button>
					</MenuButton>
					<Transition
						as={Fragment}
						enter="transition duration-100 ease-out"
						enterFrom="transform scale-95 opacity-0"
						enterTo="transform scale-100 opacity-100"
						leave="transition duration-75 ease-out"
						leaveFrom="transform scale-100 opacity-100"
						leaveTo="transform scale-95 opacity-0"
					>
						<MenuItems className="absolute right-0 z-30 mt-1 flex w-full origin-top-left flex-col divide-y divide-neutral-300 rounded-xl rounded-b-lg border border-y border-neutral-300 bg-white shadow-lg duration-200 ease-in-out transition-all focus-visible:outline-none focus-visible:border-ring focus-visible:ring-[3px] focus-visible:ring-blue-700/40">
							<div className="w-full px-4 py-2 flex items-center rounded-xl rounded-b-none duration-200 ease-in-out transition-all has-[:focus-visible]:outline-none has-[:focus-visible]:border-ring has-[:focus-visible]:ring-[3px] has-[:focus-visible]:ring-blue-700/40 has-[:focus]:z-50">
								<input
									type="text"
									placeholder="Search..."
									className="w-full outline-none text-sm"
									value={query}
									onChange={(e) => setQuery(e.target.value)}
								/>
							</div>
							<div className="flex w-full flex-col space-y-1 px-1 py-1 max-h-60 overflow-auto">
								{options
									.filter(
										({ label, alias }) =>
											norm(label)
												.toLowerCase()
												.includes(norm(query).toLowerCase()) ||
											alias?.some((a) =>
												norm(a)
													.toLowerCase()
													.includes(norm(query).toLowerCase()),
											),
									)
									.map((option) => (
										<MenuItem key={option.value}>
											<button
												type="button"
												className="rounded-lg hover:bg-neutral-100 focus:bg-neutral-100 data-[focus]:bg-neutral-100"
												onClick={() => {
													setSelected(option);
													onChange?.(option);
												}}
											>
												{render ? (
													render(option)
												) : (
													<span className="block px-3 py-1 text-left text-sm text-neutral-800">
														{option.label}
													</span>
												)}
											</button>
										</MenuItem>
									))}
							</div>
						</MenuItems>
					</Transition>
				</>
			)}
		</Menu>
	);
}
